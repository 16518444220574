import React, {useEffect, useState} from 'react';

import H1 from '../H1';
import P from '../P';
import Wrapper from './Wrapper';
import Link from './Link';
import Date from './Date';
import ContinueReading from './ContinueReading';
import Pin from "../Pin";
import ReactTooltip from "react-tooltip";
import ThumbNail from "../ThumbNail";

function Summary({date, title, snippet, slug, image, pinned, author, thumbnailText}) {

    const [_isMobile, upMobile] = useState(false);

    useEffect(() => {
        upMobile(window.matchMedia("only screen and (max-width: 870px)").matches);

        const debouncedHandleResize = debounce(function handleResize() {
            upMobile(window.matchMedia("only screen and (max-width: 870px)").matches);
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    function debounce(fn, ms) {
        let timer
        return _ => {
            clearTimeout(timer)
            timer = setTimeout(_ => {
                timer = null
                fn.apply(this, arguments)
            }, ms)
        };
    }

    return (
        //TODO: eventually add notice??

        <Wrapper>
            {image ?
                <Link to={slug}>
                    <ThumbNail fluid={{...image.childImageSharp.fluid, aspectRatio: 1.9}} alt=""/>
                </Link>
                :
                <Link to={slug}>
                    <div className={"no-thumbnail"}>
                        {thumbnailText || "Cool Text"}
                    </div>
                </Link>
            }

            <div className={"right-desc"}>
                <H1>
                    <Link to={slug}>
                        {title}
                        {pinned &&
                        <Pin data-tip data-for={"pinDesc_" + _encode_btoa(title) + "_" + _encode_btoa(date)}>
                            <svg viewBox={"0 0 489.493 489.493"} className={"pin"}>
                                <path xmlns="http://www.w3.org/2000/svg"
                                      d="M485.322,117.705c12.204-12.238-3.274-47.577-34.636-78.93c-30.99-30.99-65.76-46.396-78.401-34.941l-0.246-0.236  l-173.715,156.02c-32.117-27.993-80.684-27.038-111.278,3.534c-5.149,5.157-8.051,12.146-8.051,19.437  c0,7.292,2.901,14.283,8.051,19.431l78.808,78.801L3.902,463.627c-5.148,5.799-5.262,14.655,0.015,20.601  c5.689,6.403,15.497,6.992,21.916,1.294l182.575-162.137l7.84,7.829l40.601,40.603l0,0l30.336,30.329  c5.15,5.147,12.139,8.039,19.424,8.039c7.278,0,14.272-2.898,19.419-8.056c30.561-30.573,31.524-79.158,3.539-111.27L484.771,118.03  C484.927,117.892,485.177,117.861,485.322,117.705z"/>
                            </svg>
                        </Pin>
                        }
                    </Link>
                </H1>
                <h2>By<Link to={"/blogs/about-us#officer-team"}> {author || "TinoIoT Officers"}</Link></h2>
                <Date>{date}</Date>
                <P>{snippet}</P>
                {_isMobile ?? <ContinueReading to={slug}>Continue Reading &rarr;</ContinueReading>}
            </div>
            {pinned &&
            <ReactTooltip id={"pinDesc_" + _encode_btoa(title) + "_" + _encode_btoa(date)} place="top" type="dark">
                <span>This Post Was Pinned!</span>
            </ReactTooltip>}
        </Wrapper>
    );
}

// should be url safe AND id AND key safe... hopefully
function _encode_btoa(str) {
    return encodeURIComponent(_safe_btoa("" + str));
}

const _safe_btoa = str => {
    try {
        return btoa(str);
    } catch (err) {
        return Buffer.from(str).toString('base64')
    }
};

export default Summary;