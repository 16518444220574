import React from 'react';
import Img from 'gatsby-image';

import Wrapper from './Wrapper';

function ThumbNail({ fluid }) {
    return (
        <Wrapper>
            <Img fluid={fluid} alt="" />
        </Wrapper>
    );
}

export default ThumbNail;
