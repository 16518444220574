import Helmet from 'react-helmet';
import React, {useEffect} from 'react';

import userConfig from '../../config';

import Layout from './layout';

import Card from '../components/Card';
import Container from '../components/Container';
import Pagination from '../components/Pagination';
import Summary from '../components/Summary';

import {Link} from 'gatsby'

import {Link as RLink, navigate} from '@reach/router';

import $ from 'jquery';

import * as categoryMetaMapper from "../utils/categoryMetaMapper";

const IndexPage = ({pageContext}) => {
    const {group, index, pageCount, additionalContext} = pageContext;
    const previousUrl = index - 1 === 1 ? '/' : (index - 1).toString();
    const nextUrl = (index + 1).toString();

    let {availCategories} = additionalContext;

    availCategories = availCategories.map(a => a.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' '))

    availCategories = availCategories.map(cat => [`${cat}s`, `/${cat.toLowerCase()}s/`, cat.toLowerCase()]);

    availCategories.filter(cat => !cat.includes('//s/'));

    availCategories.sort((a, b) => b[0].length - a[0].length);

    const pinnedLinks = [
        ['All Posts', '/', '']
    ]

    // honestly dont remember what the third array item does
    const additionalLinks = [
        // ['About Us', '/blogs/about-us/', 'about-us'],
        ['What is IoT', '/blogs/all-about-iot/', 'what-is-iot'],
        // ['Past Slides', 'https://slides.tinoiot.com/', 'past-slides'],
        ['Quick Links', '/blogs/our-links/', 'our-links']
    ]

    availCategories = [...pinnedLinks, ...availCategories, ...additionalLinks];

    const curCategory = (additionalContext.curCategory || '').toLowerCase();

    useEffect(() => {
        const $cR = $(".category-wrap");
        $cR.on('click', function () {
            navigate($(this).children('a')[0].href).then(r => {
            });
        })
        return () => $cR.off('click');
    }, [])

    function titleCase(str) {
        return str.replace(/(^|\s)\S/g, function (t) {
            return t.toUpperCase()
        });
    }

    return (
        <Layout curLocation={curCategory}>
            <div className={"dropdown-indicator"} onClick={(evt) => {
                $('.dropdown-indicator').toggleClass('show-indicator')
            }} role={"presentation"}>
                <div className={"categories-bar"}>
                    <RLink to={"/"}>
                    <span className={"same-line"}>
                        <div className={"title"}>TinoIoT</div>
                        <div className={"sub-title"}> AT CHS</div>
                    </span>
                    </RLink>
                    <div className={"movable-content"}>
                        {availCategories.map(cat =>
                            <div className={"category-wrap"} data-is-current={cat[2] === curCategory || null}
                                 key={cat[1]}>
                                <Link to={cat[1]}>
                                    <div className={"sub-links"}>
                                        {cat[0]}
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                <i className={"fas fa-angle-double-down"}/>
            </div>
            <Container>
                <Helmet
                    title={`${userConfig.title}${curCategory ? ` | ${titleCase(curCategory)}s` : ``}`}
                    htmlAttributes={{lang: 'en'}}
                >
                    <meta name="description" content={categoryMetaMapper.description(curCategory || 'home')}/>

                    <meta name="keywords"
                          content={categoryMetaMapper.keywords(curCategory || 'home').join(",")}/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

                    <meta property="og:type" content="website"/>
                    <meta property="og:url"
                          content={`https://tinoiot.com/${curCategory ? curCategory + "s" : ""}`}/>
                    <meta property="og:title"
                          content={`${userConfig.title}${curCategory ? ` | ${titleCase(curCategory)}s` : ``}`}/>
                    <meta property="og:description"
                          content={categoryMetaMapper.description(curCategory || 'home')}/>
                    <meta property="og:image"
                          content={`https://tinoiot.com/res/img/main.png"}`}/>

                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url"
                          content={`https://tinoiot.com/${curCategory ? curCategory + "s" : ""}`}/>
                    <meta property="twitter:title"
                          content={`${userConfig.title}${curCategory ? ` | ${titleCase(curCategory)}s` : ``}`}/>
                    <meta property="twitter:description"
                          content={categoryMetaMapper.description(curCategory || 'home')}/>
                    <meta property="twitter:image"
                          content={`https://tinoiot.com/res/img/main.png"}`}/>

                    <script src="https://kit.fontawesome.com/913ecb688b.js" crossOrigin="anonymous"/>
                </Helmet>
                {group.map(({node}) => (
                    <Card key={node.fields.slug}>
                        <Summary
                            date={node.frontmatter.date}
                            title={node.frontmatter.title}
                            pinned={node.fields.pinned}
                            notice={node.frontmatter.notice}
                            snippet={node.snippet}
                            author={node.frontmatter.author}
                            image={node.frontmatter.featuredImage}
                            slug={node.fields.slug}
                            thumbnailText={node.frontmatter.thumbnailText}
                        />
                    </Card>
                ))}
                <Pagination
                    isFirst={index === 1}
                    isLast={index === pageCount}
                    nextUrl={nextUrl}
                    previousUrl={previousUrl}
                />
            </Container>
        </Layout>
    );
};
export default IndexPage;
