import styled from 'styled-components';

const Pin = styled.svg`
  height: 25px;
  z-index: 70;
  width: 30px;
  @media only screen and (max-width: 870px) {
    height: 21px;
    width: 17px;
  }
`;

export default Pin;