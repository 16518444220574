import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  margin: -45px -60px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .__react_component_tooltip {
    display: none;

    &.show {
      display: block;
    }
  }

  & > a {
    min-width: 43%;
    max-width: 43%;

    img, & > div {
      border-radius: 5px;
    }
  }

  & > a ~ .right-desc {
    padding-left: min(30px, 2vw);
  }

  & > a > .no-thumbnail {
    width: 100%;
    height: 95%;
    background: rgba(115, 210, 238, 0.9);
    background: linear-gradient(45deg, rgba(115, 210, 238, 0.9) 0%, rgba(15, 116, 175, 0.9) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-weight: 900;
    text-shadow: #111111cf 1px 1px 9px;
    letter-spacing: -3px;
    padding-bottom: 5px;
    color: white;
  }

  & > .right-desc {
    min-width: 54%;
    max-width: 54%;

    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;

    & > * {
      margin-left: unset;
      padding-left: unset;
    }

    & > h1 {
      text-align: start;
      line-height: 1.1;
    }

    & > h2 {
      margin: 3px 0 1px;
    }

    & > p {
      margin: 0;
      text-align: start;
    }

    & > a {
      margin-top: 3px;
    }

    & > span {
      font-size: 15px;
      margin-bottom: 3px;
    }

    & > h1 > a {
      font-size: 75%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-left: 6px
      }
    }

    & > *:not(h1) {
      padding-left: 2px;
    }
  }

  @media only screen and (max-width: 870px) {
    margin: 0;

    & > a {
      margin-top: -30px;
      margin-bottom: -30px;

      img, & > div {
        border-radius: 3px;
      }
    }

    & > .right-desc {
      margin-top: -30px;
      margin-bottom: -30px;

      & > * {
        margin-right: unset;
        padding-right: unset;
      }

      & > p {
        //display: none;
        font-size: 11px;
        margin-top: 2px;
        max-height: 31px;
        max-width: 100%;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      & > h1 {
        width: 100%;
      }

      & > h1 > a {
        font-size: 50%;
        position: relative;

        width: 100%;

        justify-content: flex-start;
        align-items: flex-start;

        svg {
          position: absolute;
          right: 0;
        }
      }

      & > h2 {
        font-size: 12px;
        margin: 0;
      }

      & > span {
        margin-bottom: 0;
        font-size: 12px;
      }
    }

    & > a > .no-thumbnail {
      max-height: min(100px, 12.5vh);
      font-size: 25px;
    }

    & > a ~ .right-desc {
      padding-left: 10px;
    }

  }
`;

export default Wrapper;