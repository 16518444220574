const categories = [
    {
        name: "home",
        description: "TinoIoT is an established club at Cupertino High School. Visit us today to learn more about using IoT (Internet of Things)!",
        keywords: ["TinoIoT", "Cupertino High School", "IoT", "Club", "Computer", "Coding", "Cupertino IoT"]
    },
    {
        name: "blog",
        description: "Read about TinoIoT's weekly blogs to learn more about club updates, announcements and more!",
        keywords: ["blog", "what is iot", "blogs", "iot blogs", "questions"]
    },
    {
        name: "tutorial",
        description: "TinoIoT tutorials encompass a large variety of topics covering software, hardware, and electronics. Create your own IoT project today!",
        keywords: ["tutorial", "software", "iot", "ESP32", "arduino", "cad"]
    },
    {
        name: "meeting",
        description: "Missed a TinoIoT meeting?  Don't worry, click here to view our past meeting minutes and catch up in no time with our debriefs!",
        keywords: ["meeting", "minute", "TinoIoT", "Cupertino High School", "CupertinoIoT"]
    }
]

const defaultMeta = {
    description: "Join TinoIoT today to learn about IoT devices! We teach hardware and electric skills including CAD-ing, circuity, and more!",
    keywords: ["Cupertino", "IoT", "Internet of Thing", "Internet of Things"]
}


module.exports = {
    description: function (category) {
        let result = defaultMeta.description
        categories.forEach(obj => {
            if (obj.name === category.toLowerCase()) {
                result = obj.description
            }
        })
        return result;
    },
    keywords: function (category) {
        let result = defaultMeta.keywords
        categories.forEach(obj => {
            if (obj.name === category.toLowerCase()) {
                result = obj.keywords
            }
        })
        return result;
    }
}
